import React, {useEffect, useState} from 'react';
import styles from './cardRow.module.scss';
import RadioButtonsNewComponent from '../../shared/radioButtonsNew';

function CardNewComponent(props) {
  const {
    ugCourseTitle = '',
    courseType = '',
    onHandleClickRadio,
    isDisabled = false,
    withoutCourses = false,
    onButtonClick,
    id,
    disableClickBanner,
    bonusIcon = false,
    audienceGrpDescription = '',
    isQuickStart = true,
  } = props;
  const [courseTypeState, setCourseTypeState] = useState('');
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setCourseTypeState(courseType);
  }, [courseType]);
  return (
    <div className={styles.card_holder}>
      {/* Below additional class "card_without_courses" is added, it should apply only to User group cards without course access*/}
      <div
        className={`${styles.card_with_courses} ${
          withoutCourses ? styles.card_without_courses : ''
        } ${isDisabled ? styles.disabled : ''}`}
        onClick={() => {
          if (isDisabled) {
            disableClickBanner();
          }
        }}
      >
        <div
          className={[styles.delete_section, isDisabled ? [styles.disabled, styles.live].join(' ') : ''].join(' ')}
          onClick={(e) => {
            if (!isDisabled) {
              onButtonClick(e);
            }
          }}
        >
          <div className={styles.delete_icon}></div>
        </div>
        <div className={[styles.course_type_section, styles.single_line_course_type].join(' ')}>
          {' '}
          {/* single_line_course_type => Use this className for single row radio item */}
          <div className={styles.title}>
            <span className={styles.title_txt}>{ugCourseTitle} </span>
            {bonusIcon && (
              <div
                className={[styles.info_icon, openPopup ? styles.selected : ''].join(' ')}
                onMouseOver={(e) => {
                  setOpenPopup(true);
                }}
                onMouseOut={(e) => {
                  setOpenPopup(false);
                }}
              >
                {openPopup && (
                  <div className={styles.tooltip_wrapper}>
                    <div className={styles.arrow_up}></div>
                    <div className={styles.tooltip_details}>
                      <div className={styles.tooltip_heading}>{ugCourseTitle} </div>
                      <div className={styles.tooptip_list}>
                        <div className={styles.tooltip_item}>{audienceGrpDescription}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={`${styles.radio_cotaniner}`}>
            <div className={styles.inner_wrapper}>
              <div className={styles.radio_wrapper}>
                <div className={styles.radio_btn}>
                  {/* <RadioButtonsNewComponent checked={courseTypeState == "Required"?true:false} onChangeHandler={(ele)=>{onHandleClickRadio(ele,id),setCourseTypeState(ele)}} item="Required"/> */}
                  <RadioButtonsNewComponent
                    checked={courseTypeState == 'Required' ? true : false}
                    onChangeHandler={(ele) => {
                      if (!isDisabled) {
                        onHandleClickRadio(ele, id), setCourseTypeState(ele);
                      }
                    }}
                    item="Required"
                  />
                </div>
                <div className={styles.course_type}>Required </div>
              </div>
              {isQuickStart ? (
                <div className={styles.radio_wrapper}>
                  <div className={styles.radio_btn}>
                    <RadioButtonsNewComponent
                      checked={courseTypeState == 'Quick Start' ? true : false}
                      onChangeHandler={(ele) => {
                        if (!isDisabled) {
                          onHandleClickRadio(ele, id), setCourseTypeState(ele);
                        }
                      }}
                      item="Quick Start"
                    />
                  </div>
                  <div className={styles.course_type}>Quick Start</div>
                </div>
              ) : null}
            </div>
            <div className={styles.inner_wrapper}>
              <div className={styles.radio_wrapper}>
                <div className={styles.radio_btn}>
                  <RadioButtonsNewComponent
                    checked={courseTypeState == 'Recommended' ? true : false}
                    onChangeHandler={(ele) => {
                      if (!isDisabled) {
                        onHandleClickRadio(ele, id), setCourseTypeState(ele);
                      }
                    }}
                    item="Recommended"
                  />
                  {/* <RadioButtonsNewComponent key={item?.id} id={item?.id} item={item} label={item?.name} onChangeHandler={()=>{}} /> */}
                </div>
                <div className={styles.course_type}>Recommended</div>
              </div>
              <div className={styles.radio_wrapper}>
                <div className={styles.radio_btn}>
                  <RadioButtonsNewComponent
                    checked={courseTypeState == 'General visibility' ? true : false}
                    onChangeHandler={(ele) => {
                      if (!isDisabled) {
                        onHandleClickRadio(ele, id), setCourseTypeState(ele);
                      }
                    }}
                    item="General visibility"
                  />
                </div>
                <div className={styles.course_type}>General visibility</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
CardNewComponent.propTypes = {};
export default CardNewComponent;
