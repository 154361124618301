export const timeDifference = (date1, date2) => {
  let difference = date1.getTime() - date2.getTime();

  let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  let minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  let secondsDifference = Math.floor(difference / 1000);

  return {
    days: daysDifference,
    hours: hoursDifference,
    minutes: minutesDifference,
    seconds: secondsDifference,
  };
};
export const characterCountWithoutSpaces = (data) => {
  let allString = data?.replace(/ /g, '');
  return allString?.length;
};
export const dateIsoToNormalDate = (dateIso) => {
  const date = new Date(dateIso);
  let dd = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
  let mm = date.getMonth() <= 8 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  return `${dd}/${mm}/${date.getFullYear()}`;
};

export const dateIsoToDate = (dateIso) => {
  const monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(dateIso);
  return `${monthArray[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const dateIsoToTime = (dateIso) => {
  const date = new Date(dateIso);
  let hours =
    Number(date.getHours().toString()) > 12
      ? Number(date.getHours().toString()) - 12
      : date.getHours();

  hours = hours > 9 ? hours : '0' + hours;
  let minutes =
    Number(date.getMinutes()) > 9 ? Number(date.getMinutes()) : '0' + Number(date.getMinutes());
  const timeFormat = Number(date.getHours().toString()) > 12 ? 'PM' : 'AM';
  return `${hours}:${minutes} ${timeFormat}`;
};

export const dummyFunction = () => {
  console.log('');
};

export const drawerOverlayCalculatedHeight = (props) => {
  const {
    drawerHeight,
  } = props;
    return drawerHeight >= 175 ? {height:175+drawerHeight} : null;
  }

export const drawerCalculatedHeight = (props) => {
  const {
    drawerHeight,
  } = props;
    return drawerHeight >= 175 ? {top:drawerHeight} : null;
  }

export const mobDrawerToOrignalState = (props) => {
  const {
    setDrawerHeight
  } = props;
    setDrawerHeight(140)
  }

export const pullbox = (props) => {
  const {
    e,
    setDrawerHeight,
  } = props;
    setDrawerHeight(e?.changedTouches[0]?.clientY)
  }