import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styles from './CreateNewLP.module.scss';
import Header from '../../shared/header';

import {
  setNotificationData,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setErrorStateTemplate,
  setLearningPath,
  setSelectedNavigationOption,
} from '../../../redux/actions/uiActions';
import {
  setMainImageBackup,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  setThumbnailImage169,
} from '../../../redux/actions/imageCrop/imageCropAction';
import {
  setNewCourseData,
  setNewCourseWizardState,
  setNewImageData,
  setNewAdaptFile,
  setBlockNavigation,
  setNewThumbnailImageData,
  setNewThumbnailId,
} from '../../../redux/actions/newCourseActions';
import {
  setNewLPWizardState,
  setNewLPData,
  setNewCourseAddedList,
  setNewAddDeleteUGMList,
  setNewLPDataCST,
} from '../../../redux/actions/learningPathActions';
import {connect} from 'react-redux';
import CreateCourseAddition from '../CreateCourseAddition';
import CourseSequencing from '../CourseSequencing';
import Notification from '../../shared/notification';
import config from '../../../config';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import Background from '../../shared/Modal/Background/Background';
import ModalWrapper from '../../shared/Modal/ModalWrapper/ModalWrapper';
import LearningPathDescription from '../LearningPathDescription';
import UserTargetingLP from '../UserTargetingLP';
import ReviewAndPublish from '../ReviewAndPublish';
import CategorySkillsTagsLP from '../CategoriesSkillsAndTags';
import {fetchLearningPathList, LPdataArray} from '../../utils/LearningPAthUtils/LandingPageUtils';
import {LPSsubheaderArray} from '../ConstantsUtil';

const {currentEnv} = config;

const CreateNewLearningPath = ({
  ApiData,
  mainImageBackup,
  setMainImageBackup,
  setNewThumbnailImageData,
  newCourseData,
  setNewCourseData,
  setNewImageData,
  setNewCourseWizardState,
  newCourseWizardState,
  setNotificationData,
  notificationData,
  setLearningPath,
  newThumbnailId,
  showCreateNewCoursesWizard,
  setBlockNavigation,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  currentUserId,
  openNewCourse = false,
  setNewThumbnailId,
  thumbnailImage169,
  setThumbnailImage169,
  setErrorStateTemplate,
  setNewLPWizardState,
  setNewLPData,
  newLPDataCST,
  setNewLPDataCST,
  setNewCourseAddedList,
  setNewAddDeleteUGMList,
  newLPData,
  newLPWizardState,
  setSelectedNavigationOption,
  addedCourseListState,
  newUGMListState,
  allProps,
  allState,
}) => {
  const [nextState, setNextState] = useState(true);
  const [editState, setEditState] = useState(false);
  const [openArchivedModal, setOpenArchivedModal] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [isPublishButton, setIsPublishButton] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [publishButtonDisableEdit, setPublishButtonDisableEdit] = useState(false);
  const [saveAndExitDisable, setSaveAndExitDisable] = useState(false);
  const [btn2SaveAndExitDisable, setBtn2SaveAndExitDisable] = useState(true);
  const [exitButtonEnable, setExitButtonEnable] = useState(false);
  const [disableImageLib, setDisableImageLib] = useState(false);
  const [imageFileDesk, setImageFileDesk] = useState(null);
  const [data, setData] = useState(null);
  const [dataLP, setDataLP] = useState(null);
  const [categories, setCategories] = useState([]);
  const [audienceGroup, setAudienceGroup] = useState([]);
  const [skills, setSkills] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [pillsAddAudienceGroup, setPillsAddAudienceGroup] = useState([]);
  const [pillsDataKeyword, setPillsDataKeyword] = useState([]);
  const [typesCheckbox, setTypeCheckbox] = useState();
  const [addUserGroupList, setAddUserGroupList] = useState([]);
  const [addUserGroupListCopy, setAddUserGroupListCopy] = useState([]);
  const [disableStateUserManagement, setDisableStateUserManagement] = useState(false);
  const [deleteUserGroupList, setDeleteUserGroupList] = useState([]);
  const containerRef = useRef();
  const router = useHistory();
  const {query = {}} = router.location;
  const [stepProgress, setStepProgress] = useState(1);
  const [input, setInput] = useState('');
  const [addedCourseList, setAddedCourseList] = useState([]);
  const [keywordsListFromLpDetails, setKeywordsListFromLpDetails] = useState([]);
  const [categoriesListFull, setCategoriesListFull] = useState([]);
  const [keywordsListFull, setKeywordsListFull] = useState([]);
  const [skillsListFull, setSkillsListFull] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [list, setList] = useState(LPSsubheaderArray);
  const [certificateList, setCertificateList] = useState([]);
  const [subheader, setSubheader] = useState('');
  const [subheadersearch, setSubheaderSearch] = useState('');
  const [isOnline, setOnline] = useState(true);
  const [publishLpButtonEnable, setPublishLpButtonEnable] = useState(false);
  const LPState = data?.state ? data?.state : '';
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

  //   const location = useLocation()
  // location.pathname

  // const history = useHistory()
  // history.location.pathname
  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });
  window.addEventListener('popstate', (event) => {
    // setNewLPData(null);
    // setData(null);
  });
  window.addEventListener('onbeforeunload ', (ev) => {
    ev.preventDefault();
    return (ev.returnValue = 'Are you sure you want to close?');
  });

  const closeCourseWizard = () => {
    setTimeout(() => {
      router.push({
        pathname: '/LearningPathModal',
        query: {learningPathId: data.learningPathId},
      });
    }, 500);
    setTimeout(() => {
      setMainImageBackup(null);
      setData(null);
      setDataLP(null);
      setNewLPData(null);
      setNewThumbnailId(null);
      setNewCourseAddedList([]);
      setNewAddDeleteUGMList(null);
      setLearningPath('');
    }, 1500);
  };
  const getLpStatusRecall = () => {
    if (LPState === 'Offline' || LPState === 'Live') {
      return true;
    } else {
      return false;
    }
  };

  const handleDataFill = (name, value) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
    if (
      getEnableSaveAndExit({
        englishTitle: name == 'primaryTitle' ? value : data?.primaryTitle,
        englishDescription: name == 'primaryDescription' ? value : data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };
  const handleDataFillMultiLingual = (name, value, eleData) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    let tempObj =
      eleData?.multilingualDetails && eleData?.multilingualDetails[0]
        ? eleData?.multilingualDetails[0]
        : {};
    tempObj[name] = value;
    tempObj.locale = 'zh-tw';

    setData((prevData) => ({
      ...prevData,
      multilingualDetails: [tempObj],
    }));
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };

  const handleDataFillAudienceGroups = (name, value) => {
    let deleteAudGrpIdsArray = [];

    if (data?.audienceGroups?.length > 0) {
      data?.audienceGroups?.map((ele) => {
        if (!value?.includes(ele?.id)) {
          deleteAudGrpIdsArray.push(ele?.id);
        }
      });
    }
    let tmpObj = {
      deleteAudGrpIds: deleteAudGrpIdsArray,
      addAudGrpIds: value,
    };

    setData((prevData) => ({
      ...prevData,
      ['audienceGroups']: tmpObj,
    }));
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };

  const updateCourseDetails = async (learningPathId, courseDetails, isPublishData = false) => {
    setNewThumbnailId('');
    const dataCourseID = await makeRequest({
      method: 'PUT',
      endpoint: `/admin/api/v1/learning-paths/${learningPathId}`,
      body: JSON.stringify(courseDetails),
    });
    if (dataCourseID) {
      //fetchSelectedCourse(dataCourseID?.data?.entry?.uid);
      //setData(dataCourseID?.data);
      if (!isPublishData && courseDetails.state !== 'Draft') {
        setNotificationData({
          show: true,
          title: 'Learning Path Edited',
          description: `${courseDetails?.primaryTitle} has been edited by the ${oktaToken.idToken.claims.fullName}`,
          type: 'SUCCESS',
        });
      }
      setTimeout(() => {
        closeCourseWizard();
      }, 1000);
    }
    setSaveAndExitDisable(false);
    //setBtn2SaveAndExitDisable(false);

    setNotificationListApi();
    setDashboardNotificationCountApi(currentUserId);
  };
  const createAudienceLists = (list) => {
    let allList = [];
    list?.forEach((element) => {
      let tempObj = {
        title: element?.audienceGrpName,
        id: element?.audienceGroupId,
        audienceGrpDescription: element?.audienceGrpDescription,
      };
      allList.push(tempObj);
    });
    //setDefaultAudienceGroup(defaultList);
    setAudienceGroup(allList);
  };
  const fetchAudienceGroupList = async () => {
    const dataAudience = await makeRequest({
      method: 'GET',
      endpoint: '/admin/api/v1/audience-groups?sortBy=oldest_to_newest',
    });
    if (dataAudience != 'ERR_NETWORK' && dataAudience) {
      createAudienceLists(dataAudience?.data);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
    } else if (dataAudience == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
    }
  };
  const fetchCategories = async () => {
    const dataCategories = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/categories',
    });
    if (dataCategories) {
      setCategories(dataCategories?.data);
    }
  };

  const fetchSkills = async () => {
    const dataSkills = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/skills',
    });
    if (dataSkills) {
      setSkills(dataSkills?.data);
      let tempArr = [];
      let tempArrNew = [];
      tempArr = [...dataSkills?.data, ...skillsListFull];
      tempArrNew = [...new Set(tempArr)];
      setSkillsListFull(tempArrNew);
    }
  };

  const fetchKeywords = async () => {
    const dataKeywords = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/keywords',
    });
    if (dataKeywords) {
      setKeyword(dataKeywords?.data);
    }
  };
  const fetchCertificatescategories = async () => {
    const dataKeywords = await makeRequest({
      method: 'GET',
      endpoint: '/admin/api/v1/certificates',
    });
    if (dataKeywords) {
      setCertificateList(dataKeywords?.data);
    }
  };

  const checkFun = (eleData) => {
    if (
      eleData &&
      (eleData?.primaryDescription?.length > 2 || eleData?.multilingualDetails[0].title > 2) &&
      !exitButtonEnable
    ) {
      const learningPathId = data && data.learningPathId;
      let elementData = LPdataArray(
        eleData,
        addedCourseList,
        pillsDataKeyword,
        selectedSkills,
        categoriesListFull,
      );
      updateCourseDetails(learningPathId, elementData);
    }
  };

  const saveAndExitClick = () => {
    if (data?.courseId === null || data?.courseId === undefined) {
      callCreateCourseAPI();
    } else {
      checkFun(data);
    }

    setMainImageBackup(null);
    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });
    setNewImageData(null);
    setCroppedImageData(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
    setSelectedImage_16_9_Backup(null);
    setSelectedImage_1_1_Backup(null);
    setTimeout(() => {
      //fetchCoursesList();
    }, 5000);
  };
  const allCheckFunc = () => {
    if (
      data &&
      data?.primaryTitle?.length > 0 &&
      data?.primaryTitle !== '' &&
      data?.data?.multilingualDetails[0].title?.length > 0 &&
      data?.data?.multilingualDetails[0].title !== '' &&
      data?.primaryDescription?.length > 0 &&
      data?.primaryDescription !== '' &&
      data?.data?.multilingualDetails[0].description?.length > 0 &&
      data?.data?.multilingualDetails[0].description !== '' &&
      ((data?.author?.length && data?.author !== '' > 0) ||
        (data?.primarySpeaker?.length && data?.primarySpeaker !== '' > 0)) &&
      ((data?.author?.length && data?.author !== '' > 0) ||
        (data?.primarySpeaker?.length && data?.primarySpeaker !== '' > 0)) &&
      (data?.thumbnailId !== '' || data?.thumbnail !== '') &&
      data?.thumbnailId !== '' &&
      data?.category !== '' &&
      data?.category?.length > 0
      //data?.skills?.length > 0 &&
      //data?.keywords?.length > 0 &&
    ) {
      //setNextButtonDisable(false);
      return true;
    } else {
      //setNextButtonDisable(true);
      return false;
    }
  };

  const getEnableSaveAndExit = ({englishTitle = '', englishDescription = ''}) => {
    let flag = false;

    if (englishTitle?.length > 0 && englishDescription?.length > 0) {
      if (englishTitle?.length >= 3 && englishDescription?.length >= 3) {
        flag = true;
      }
    } else if (englishTitle?.length > 0 || englishDescription?.length > 0) {
      if (englishTitle?.length >= 3 && englishDescription?.length >= 3) {
        flag = true;
      }
    }
    return flag;
  };
  React.useEffect(() => {
    const unblock = router.block((location, action) => {
      if (newCourseWizardState.courseWizardOpen) {
        closeCourseWizard();
        if (newCourseWizardState.section.length > 0) {
          return true;
        } else if (newCourseWizardState.section.length <= 0) {
          if (action === 'PUSH') {
            return true;
          }
          return false;
        }
      }
      return true;
    });
    setBlockNavigation(true);
    setSelectedNavigationOption('LEARNING-PATH');
    return () => {
      unblock();
      setBlockNavigation(false);
      window.removeEventListener('beforeunload ', (ev) => {
        ev.preventDefault();
        return (ev.returnValue = 'kkk');
        //    setNotificationData({
        //   show: true,
        //   type: 'FAILURE',
        //   title: 'Learning Path Not Saved',
        //   description: 'Save & Exit to save this Learning Path in a Draft state.',
        // }));
      });
    };
  }, []);

  useEffect(async () => {
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
    ) {
      let tempArr = [];
      let headerIndex = list.findIndex((data) => {
        return data.title == subheader;
      });
      if (headerIndex !== null && headerIndex !== -1) {
        list.forEach((data, index) => {
          tempArr.push({
            className: data.className,
            title: data.title,
            subHeaderClick: true,
          });
        });
      }
      setList(tempArr);
      setPublishButtonDisableEdit(false);
      setExitButtonEnable(true);
      setNextButtonDisable(false);
      !publishLpButtonEnable &&
        data?.state == 'Draft' &&
        subheader == 'Learning Path Description' &&
        setBtn2SaveAndExitDisable(false);
    } else {
      let tempArr = [];
      list.forEach((data, index) => {
        tempArr.push({
          className: data.className,
          title: data.title,
          subHeaderClick: false,
        });
      });
      setList(tempArr);
      setNextButtonDisable(true);
      setBtn2SaveAndExitDisable(true);
      setExitButtonEnable(false);
      //setBlockNavigation(false);
    }
    if (allCheckFunc() && nextState) {
      setNextButtonDisable(false);
      !editState && setExitButtonEnable(false);
      setPublishButtonDisableEdit(false);
    } else {
      !allCheckFunc() && nextState && setNextButtonDisable(true);
    }
  }, [data]);
  const allCheckLPFunc = () => {
    const LPState = data?.state ? data?.state : '';
    const invalidCourse = addedCourseList.filter((ele) => ele.courseState !== 'Live');
    if (
      data &&
      isDescription &&
      isThumbnail &&
      isTitle &&
      // ((data?.author?.length && data?.author !== '' > 0) ||
      //   (data?.primarySpeaker?.length && data?.primarySpeaker !== '' > 0)) &&
      ((invalidCourse?.length === 0 && (LPState == 'Draft' || LPState == '')) ||
        LPState == 'Live' ||
        LPState == 'Offline') &&
      addedCourseList.length >= 2
    ) {
      //setNextButtonDisable(false);
      return true;
    } else {
      //setNextButtonDisable(true);
      return false;
    }
  };
  useEffect(async () => {
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      }) &&
      (subheader == 'Course Addition' || subheader == 'Learning Path Description')
    ) {
      setPublishButtonDisableEdit(false);
      setExitButtonEnable(true);
      setNextButtonDisable(false);

      !getLpStatusRecall() && setBtn2SaveAndExitDisable(false);
    } else {
      setNextButtonDisable(true);
      //setBtn2SaveAndExitDisable(true);
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
        ? setExitButtonEnable(true)
        : setExitButtonEnable(false);
      //setBlockNavigation(false);
    }
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      }) &&
      subheader == 'Course Sequencing'
    ) {
      setExitButtonEnable(true);
      !getLpStatusRecall() && setBtn2SaveAndExitDisable(false);
      setIsPublishButton(false);
    }
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      }) &&
      subheader == 'User Group Targeting' &&
      (addUserGroupList.length > 0 || deleteUserGroupList.length > 0)
    ) {
      setExitButtonEnable(true);
      !getLpStatusRecall() && setBtn2SaveAndExitDisable(false);
      setIsPublishButton(false);
    }
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      }) &&
      subheader == 'Categories, Skills, Tags'
      //  &&
      // categoriesListFull.length > 0
      //&&
      // selectedSkills.length > 0 &&
      // keyword.length > 0
    ) {
      setExitButtonEnable(true);
      !getLpStatusRecall() && setBtn2SaveAndExitDisable(false);
      setIsPublishButton(false);
    }
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      }) &&
      subheader == 'Review & Publish'
    ) {
      setExitButtonEnable(true);
      // refreshList();
      if (allCheckLPFunc() && !publishLpButtonEnable) {
        !getLpStatusRecall() && setBtn2SaveAndExitDisable(false);
      } else if (!allCheckLPFunc() && !publishLpButtonEnable) {
        setBtn2SaveAndExitDisable(true);
      }
      setIsPublishButton(true);
    }
    if (subheader !== 'Review & Publish') {
      setIsPublishButton(false);
    }
  }, [subheader, addedCourseList]);

  const fetchLPDetails = async (id, selectedSubHeader) => {
    setIsLoadingApi(true);
    let courseListSorted = [];
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/courses',
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      dataCourses?.data?.forEach((data) => {
        if (data.courseState != 'Archive') {
          courseListSorted.push(data);
        }
      });
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
    }

    //Learning path api calling
    let endpoint = `/admin/api/v1/learning-paths/${id}`;
    const dataLearningPath = await makeRequest({
      method: 'GET',
      endpoint,
    });
    if (dataLearningPath) {
      if (dataLearningPath.data?.state == 'Live' || dataLearningPath.data?.state == 'Offline') {
        selectedSubHeader == 'categories_skills_tags'
          ? setSubheader('Categories, Skills, Tags')
          : setSubheader('Review & Publish');
      }
      if (dataLearningPath.data?.state == 'Draft') {
        selectedSubHeader == 'categories_skills_tags'
          ? setSubheader('Categories, Skills, Tags')
          : setSubheader('Learning Path Description');
      }
      if (dataLearningPath?.data?.courses?.length > 0) {
        let tempArr = [];
        if (dataLearningPath.data?.state == 'Live' || dataLearningPath.data?.state == 'Offline') {
          // tempArr = dataLearningPath?.data?.courses?.filter((el) => {
          //   return dataCourses?.data?.some((element) => {
          //     return element.courseId === el.id;
          //   });
          // });
          dataLearningPath?.data?.courses.forEach((el) => {
            dataCourses?.data?.some((element) => {
              if (element.courseId === el.id) {
                return tempArr.push(element);
              }
            });
          });
        } else if (dataLearningPath.data?.state == 'Draft') {
          if (
            getEnableSaveAndExit({
              englishTitle: dataLearningPath.data?.primaryTitle,
              englishDescription: dataLearningPath.data?.primaryDescription,
            }) &&
            subheader == 'Learning Path Description'
          ) {
            setBtn2SaveAndExitDisable(false);
          }
          dataLearningPath?.data?.courses.forEach((el) => {
            dataCourses?.data?.some((element) => {
              if (
                element.courseId === el.id &&
                el.state !== 'Archive' &&
                element.state !== 'Archive'
              ) {
                return tempArr.push(element);
              }
            });
          });
          // tempArr = dataLearningPath?.data?.courses?.filter((el) => {
          //   return courseListSorted?.some((element) => {
          //     return (
          //       element.courseId === el.id && el.state !== 'Archive' && element.state !== 'Archive'
          //     );
          //   });
          // });
        }
        setAddedCourseList(tempArr);
        let tempArr2 = [];
        let tempObj2 = {};
        tempArr.forEach((data) => {
          tempArr2.push(data.courseId);
        });
        tempObj2 = {
          courseIds: tempArr2,
        };
        await fetchCoursesListRefresh(tempObj2);
        setKeywordsListFromLpDetails([...dataLearningPath?.data?.keywords]);
      }
      if (dataLearningPath?.data?.skills?.length > 0) {
        let tempArr = [];
        dataLearningPath?.data?.skills?.forEach((ele) => {
          tempArr.push(ele);
        });
        setSelectedSkills(tempArr);
      }
      if (dataLearningPath?.data?.audienceGroups?.length > 0 && !newUGMListState) {
        let tempArr = [];
        dataLearningPath?.data?.audienceGroups?.forEach((ele) => {
          let tempObj = {
            id: ele.id,
            courseType: ele.type,
            title: ele.title,
            audienceGrpDescription: ele?.description,
          };
          tempArr.push(tempObj);
        });
        setAddUserGroupList(tempArr);
        setAddUserGroupListCopy(new Array(...tempArr));
      }
      if (dataLearningPath?.data?.audienceGroups?.length == 0 && !newUGMListState) {
        let tempArr = [];
        setAddUserGroupList(tempArr);
        setAddUserGroupListCopy(new Array(...tempArr));
      }
      dataLearningPath?.data?.thumbnail && setMainImageBackup(dataLearningPath?.data.thumbnail);

      setData(dataLearningPath?.data);
      setDataLP(dataLearningPath?.data);
    }
  };
  useEffect(() => {
    let tempArr = [];
    const results = keywordsListFromLpDetails?.filter(
      ({id: id1}) => !keywordsListFull.some(({id: id2}) => id2 === id1),
    );
    tempArr = [...new Set(results)];
    setPillsDataKeyword(tempArr);
  }, [keywordsListFull, keywordsListFromLpDetails]);
  useEffect(async () => {
    setData(null);
    setDataLP(null);
    //fetchAudienceGroupList
    // fetch categories
    if (
      router.location.pathname == '/newLearningPath' &&
      router.location.search &&
      newLPData == null
    ) {
      let string = router.location.search;
      let stringArray = string?.split('&');
      let strArr1 = stringArray[0]?.split('=');
      let strArr2 = stringArray[1]?.split('=');
      let selectedID = strArr1[1];
      let selectedSubHeader = strArr2[1];
      setSubheaderSearch(selectedSubHeader);
      if (selectedSubHeader == 'learning_path_description') {
        setSubheader('Learning Path Description');
      } else if (selectedSubHeader == 'course_addition') {
        setSubheader('Course Addition');
      } else if (selectedSubHeader == 'course_sequencing') {
        setSubheader('Course Sequencing');
      } else if (selectedSubHeader == 'user_group_targeting') {
        setSubheader('User Group Targeting');
      } else if (selectedSubHeader == 'categories_skills_tags') {
        setSubheader('Categories, Skills, Tags');
      } else if (selectedSubHeader == 'review_and_publish') {
        setSubheader('Review & Publish');
      }
      if (query.id !== null && !selectedID) {
        await fetchLPDetails(query.id, selectedSubHeader);
      }
      if (
        !query.id &&
        selectedID != 'null' &&
        selectedID != null &&
        selectedID != undefined &&
        selectedID != 'undefined'
      ) {
        await fetchLPDetails(selectedID, selectedSubHeader);
      }
    }
    await fetchSkills();
    await fetchAudienceGroupList();
    await fetchCategories();
    // fetch skills

    // fetch keyword
    await fetchKeywords();
    await fetchCertificatescategories();

    if (
      (query.id == null || query.id == undefined) &&
      (newThumbnailId == undefined || newThumbnailId == null) &&
      (newLPData?.thumbnailId == null || newLPData?.thumbnailId == undefined) &&
      newLPData?.thumbnail.length == 0
    ) {
      setMainImageBackup(null);
      //setData(null);
    }
    if (newLPData?.thumbnail && newThumbnailId == null) {
      setMainImageBackup(newLPData?.thumbnail);
    }
    //handleUserTargetingGroupsList()
    setTimeout(() => {
      setIsLoadingApi(false);
    }, 1500);
    return () => {
      setData(null);
      setDataLP(null);
      setPillsDataKeyword(null);
    };
  }, []);
  useEffect(async () => {
    // setPublishButtonDisableEdit(true);
    if (ApiData && !openNewCourse) {
      setNewCourseData(null);
      setData((prevData) => ({
        ...prevData,
        ...ApiData,
        types: ApiData?.types?.length > 0 ? ApiData?.types : undefined,
      }));
      ApiData?.courseState === 'Live' && setDisableStateUserManagement(true);
      ApiData?.courseState === 'Offline' && setDisableStateUserManagement(false);
      !newUGMListState && setAddUserGroupList(ApiData?.audienceGroups);
      !newUGMListState && setAddUserGroupListCopy(new Array(...ApiData?.audienceGroups));
      setPillsAddAudienceGroup(ApiData?.audienceGroups);
      setPillsDataKeyword(ApiData?.keywords);
      setMainImageBackup(ApiData?.thumbnail);
      setTypeCheckbox(ApiData?.types?.toString());
    }
  }, [ApiData]);
  const handleClickOutsideContainer = (event) => {
    if (
      (data?.primaryTitle?.length >= 3 ||
        (data?.multilingualDetails && data?.multilingualDetails[0]?.title?.length >= 3) ||
        data?.learningPathId) &&
      containerRef &&
      !containerRef.current?.contains(event.target) &&
      event.target.className.includes('SideNavigation') &&
      !(
        event.target.className.indexOf('CourseWizard') !== -1 ||
        event.target.className.indexOf('Header') !== -1 ||
        event.target.className.indexOf('CreateNewLP') !== -1 ||
        event.target.className.indexOf('CourseSequencing') !== -1 ||
        event.target.className.indexOf('CourseAddition') !== -1
      )
    ) {
      event.preventDefault();
      setBlockNavigation(true);
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Learning Path Not Saved',
        description: 'Save & Exit to save the information on Learning Path.',
      });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideContainer, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainer, false);
      document.removeEventListener('click', handleClickOutsideContainer, false);
    };
  }, [data]);
  const editSaveAndExitEnable = (data) => {
    //setBtn2SaveAndExitDisable(false);
    if (
      (data?.primaryTitle?.length >= 3 && data?.primaryDescription?.length >= 3) ||
      (newCourseData?.data?.primaryTitle?.length >= 3 &&
        newCourseData?.data?.primaryDescription?.length >= 3)
    ) {
      setBtn2SaveAndExitDisable(false);
    } else {
      setBtn2SaveAndExitDisable(true);
    }
  };

  const filterListById = (id, list, setlist, name, stateFunction) => {
    let results = list?.filter((element) => {
      return element?.id !== id;
    });
    setlist(results);
    let tempArr = [];
    results?.forEach((data) => {
      tempArr.push(data.id);
    });
    stateFunction(name, tempArr);
  };

  const uploadNewImagePath = () => {
    router.push({
      pathname: '/add-new-image',
      //query: {actionName: ""},
    });
  };

  const uploadImageFromDesktop = (event, file) => {
    setImageFileDesk(file);
    setNewImageData(event);
    // const test = .readAsDataURL(data)
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64_data = reader.result;

      //setMainImg(base64_data);
      setMainImageBackup(base64_data);
    };
    uploadNewImagePath();
  };

  useEffect(() => {
    if (newThumbnailId?.length > 0 && mainImageBackup?.length > 0) {
      handleDataFill('thumbnailId', newThumbnailId);
      setBtn2SaveAndExitDisable(false);
    }
  }, [mainImageBackup]);

  useEffect(() => {
    if (!ApiData && newLPData) {
      setTypeCheckbox(newLPData?.types?.toString());

      setData((prevData) => ({
        ...prevData,
        ...newLPData,
        thumbnailId: newThumbnailId ? newThumbnailId : newLPData?.thumbnailId,
      }));
      setDataLP((prevData) => ({
        ...prevData,
        ...newLPData,
        thumbnailId: newThumbnailId ? newThumbnailId : newLPData?.thumbnailId,
      }));
    }
    if (newThumbnailId == null && newLPData?.thumbnail) {
      setMainImageBackup(newLPData?.thumbnail);
    }
  }, [newLPData]);
  useEffect(() => {
    if (!ApiData && newLPDataCST) {
      setCategoriesListFull(newLPDataCST?.categories);
      setPillsDataKeyword(newLPDataCST?.keywords);
      setKeywordsListFromLpDetails(newLPDataCST?.keywords);
      setSelectedSkills(newLPDataCST?.skills);
    }
  }, [newLPDataCST]);

  useEffect(() => {
    if (!ApiData && addedCourseListState) {
      setAddedCourseList(addedCourseListState);
    }
  }, [addedCourseListState]);
  useEffect(() => {
    if (newUGMListState) {
      setAddUserGroupList(newUGMListState.addUserGroupList);
      setDeleteUserGroupList(newUGMListState.deleteUserGroupList);
    }
  }, [newUGMListState]);

  const changeLearningPathStatus = async (learningPathId, state) => {
    const apiData = await makeRequest({
      method: 'PATCH',
      endpoint: `/admin/api/v1/learning-paths/${learningPathId}/${state}`,
    });
    if (apiData) {
      setNotificationData({
        show: true,
        title: 'Learning Path Archived.',
        description: `${dataLP?.primaryTitle} has been archived by the ${oktaToken.idToken.claims.fullName}.`,
        type: 'SUCCESS',
      });
      closeCourseWizard();
      setNotificationListApi();
      setDashboardNotificationCountApi(currentUserId);
    }
    closeCourseWizard();
  };

  const publishLpData = (publishCourse = false) => {
    setBtn2SaveAndExitDisable(true);
    setSaveAndExitDisable(true);
    setPublishLpButtonEnable(true);
    let publishData = Object.assign({}, data);
    let tempCategories = [];
    let tempSkills = [];
    let tempKeywords = [];
    let addUserList = [];
    let deleteUserList = [];
    let tempKeywordsNew = [];
    let tempSkillsNew = [];
    if (pillsDataKeyword) {
      pillsDataKeyword.forEach((ele) => {
        tempKeywords.push(ele.id);
      });
    }
    // data?.keywords?.map((ele) => {
    //   ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
    // });
    if (selectedSkills) {
      selectedSkills.forEach((ele) => {
        tempSkills.push(ele.id);
      });
    }
    if (categoriesListFull) {
      categoriesListFull.forEach((ele) => {
        tempCategories.push(ele.id);
      });
    }
    addUserGroupList?.forEach((ele) => {
      addUserList?.push({
        audGrpId: ele?.id || ele?.audGrpId,
        learningPathType: ele?.courseType,
      });
    });
    deleteUserGroupList?.forEach((ele) => {
      deleteUserList?.push({
        audGrpId: ele?.id || ele?.audGrpId,
        learningPathType: ele?.courseType,
      });
    });
    publishData.audienceGroups = {
      deleteAudGrpIds: deleteUserList,
      addAudGrpIds: addUserList,
    };
    if (publishCourse) {
      publishData.state = 'Live';
    }

    tempKeywordsNew = [...new Set(tempKeywords)];
    tempSkillsNew = [...new Set(tempSkills)];
    publishData.categories = tempCategories;
    publishData.skills = tempSkillsNew;
    publishData.keywords = tempKeywordsNew;
    let LPDetailsCoursesList = [];
    addedCourseList.forEach((ele) => {
      LPDetailsCoursesList.push(ele.courseId);
    });
    publishData.courses = LPDetailsCoursesList ? LPDetailsCoursesList : [];
    if (data?.learningPathId) {
      updateCourseDetails(data?.learningPathId, publishData, publishCourse);
    } else {
      CreateNewLPApi(publishData);
    }
    setTimeout(() => {
      setMainImageBackup(null);
      setNewCourseData(null);
      // setData(null);
      // setDataLP(null);
    }, 2002);
  };
  const CreateNewLPApi = async (publishData) => {
    setBtn2SaveAndExitDisable(true);

    const apiData = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/learning-paths',
      body: JSON.stringify(publishData),
    });
    if (apiData) {
      setData((prevData) => ({
        ...prevData,
        ...apiData?.data,
      }));
      setTimeout(() => {
        closeCourseWizard();
      }, 1002);
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: 'New Learning Path Launched',
        description: `${publishData?.primaryTitle} has been launched by the ${oktaToken.idToken.claims.fullName}`,
      });
      setNotificationListApi();
      setDashboardNotificationCountApi(currentUserId);
      // setBtn2SaveAndExitDisable(false);
      setSaveAndExitDisable(false);
    }
  };
  const updateEditCourseDetails = async () => {
    let publishData = Object.assign({}, data);
    let tempCategories = [];
    let tempSkills = [];
    let tempKeywords = [];
    let tempKeywordsNew = [];
    let tempSkillsNew = [];
    let addUserList = [];
    let deleteUserList = [];
    // data?.skills?.map((ele) => {
    //   ele.id ? tempSkills.push(ele.id) : tempSkills.push(ele);
    // });
    // data?.keywords?.map((ele) => {
    //   ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
    // });
    if (pillsDataKeyword) {
      pillsDataKeyword.forEach((ele) => {
        tempKeywords.push(ele.id);
      });
    }
    if (selectedSkills) {
      selectedSkills.forEach((ele) => {
        tempSkills.push(ele.id);
      });
    }
    if (categoriesListFull) {
      categoriesListFull.forEach((ele) => {
        tempCategories.push(ele.id);
      });
    }
    tempKeywordsNew = [...new Set(tempKeywords)];
    tempSkillsNew = [...new Set(tempSkills)];
    publishData.date = new Date().toISOString();
    if (publishData.state == undefined || publishData.state == null || publishData.state == '') {
      publishData.state = 'Draft';
    }

    if (
      publishData?.primaryTitle?.length > 1 &&
      publishData?.data?.multilingualDetails[0].title?.length > 1
    ) {
      publishData.isMultilingual = Boolean(true);
    } else {
      publishData.isMultilingual = Boolean(false);
    }
    if (publishData?.types?.length > 0) {
      publishData.types = publishData?.types;
    }
    // else {
    //   publishData.types = publishData?.types;
    // }
    // if (publishData.courses) {
    //   let tempArrCourse = [];
    //   publishData?.courses?.forEach((ele) => {
    //     tempArrCourse.push(ele.id);
    //   });
    //   publishData.courses = tempArrCourse;
    // }
    let tempArrCourse = [];
    addedCourseList.forEach((ele) => {
      tempArrCourse.push(ele.courseId);
    });
    publishData.courses = tempArrCourse;
    delete publishData?.id;
    delete publishData?.courseUrl;
    delete publishData?.thumbnail;
    delete publishData?.updatedAt;
    delete publishData?.updatedBy;
    // delete publishData?.types;
    delete publishData?.course_state;
    delete publishData?.filename;
    //delete publishData?.isMultilingual;
    delete publishData?.launchDate;
    delete publishData?.category;
    delete publishData?.skills;
    delete publishData?.keywords;
    delete publishData?.createdBy;

    publishData.categories = tempCategories;
    publishData.skills = tempSkillsNew;
    publishData.keywords = tempKeywordsNew;
    // publishData.version = data?.version;

    if (publishData?.thumbnailId === null) {
      delete publishData?.thumbnailId;
    }
    // if (publishData?.adaptFileName === null) {
    //   delete publishData?.adaptFileName;
    // }
    addUserGroupList?.forEach((ele) => {
      addUserList?.push({
        audGrpId: ele?.id || ele?.audGrpId,
        learningPathType: ele?.courseType,
      });
    });
    deleteUserGroupList?.forEach((ele) => {
      deleteUserList?.push({
        audGrpId: ele?.id || ele?.audGrpId,
        learningPathType: ele?.courseType,
      });
    });
    publishData.audienceGroups = {
      deleteAudGrpIds: deleteUserList,
      addAudGrpIds: addUserList,
    };
    await updateCourseDetails(data?.learningPathId, publishData, false);

    setImageData({
      imageName: null,
      imageDescription: null,
      pillsData: null,
    });
    setNewImageData(null);

    setCroppedImageData(null);
    setCroppedImage_16_9(null);
    setCroppedImage_1_1(null);
    setSelectedImage_16_9_Backup(null);
    setSelectedImage_1_1_Backup(null);
    setNewCourseData(null);
  };

  const createNewCourse = async (file) => {
    setBtn2SaveAndExitDisable(true);
    let LPDetails = {};
    let LPDetailsCoursesList = [];
    let tempCategories = [];
    let tempSkills = [];
    let tempSkillsNewAdd = [];
    let tempKeywords = [];
    let tempKeywordsNew = [];
    addedCourseList.forEach((ele) => {
      LPDetailsCoursesList.push(ele.courseId);
    });
    LPDetails = data;
    LPDetails.courses = LPDetailsCoursesList ? LPDetailsCoursesList : [];
    if (data?.audienceGroups?.addAudGrpIds?.length > 0) {
      let addAudGrpIdsList = [];
      data?.audienceGroups?.addAudGrpIds.forEach((ele) => {
        addAudGrpIdsList.push({
          audGrpId: ele.audGrpId,
          learningPathType: ele.courseType,
        });
      });
      LPDetails.audienceGroups = {
        deleteAudGrpIds: [],
        addAudGrpIds: addAudGrpIdsList || [],
      };
    } else {
      delete LPDetails.audienceGroups;
    }
    delete LPDetails?.launchDate;
    delete LPDetails?.category;
    delete LPDetails?.skills;
    delete LPDetails?.keywords;
    delete LPDetails?.createdBy;
    // data?.keywords?.map((ele) => {
    //   ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
    // });
    if (selectedSkills) {
      selectedSkills.forEach((ele) => {
        tempSkills.push(ele.id);
      });
    }
    if (categoriesListFull) {
      categoriesListFull.forEach((ele) => {
        tempCategories.push(ele.id);
      });
    }
    if (pillsDataKeyword) {
      pillsDataKeyword.forEach((ele) => {
        tempKeywords.push(ele.id);
      });
    }
    tempKeywordsNew = [...new Set(tempKeywords)];
    tempSkillsNewAdd = [...new Set(tempSkills)];
    LPDetails.categories = tempCategories;
    LPDetails.skills = tempSkillsNewAdd;
    LPDetails.keywords = tempKeywordsNew;
    const apiData = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/learning-paths',
      body: JSON.stringify(LPDetails),
    });
    if (apiData) {
      setData((prevData) => ({
        ...prevData,
        ...apiData?.data,
      }));
      // setBtn2SaveAndExitDisable(false);
    }
  };

  const callCreateCourseAPI = async (file) => {
    const {primaryTitle, primaryDescription} = data;
    if (data.learningPathId === null || data.learningPathId === undefined) {
      if (primaryTitle?.length >= 3 && primaryDescription?.length >= 3) {
        await createNewCourse(file);
      } else {
        return;
      }
    }
  };
  const button1Name = () => {
    if (exitButtonEnable && data?.state !== 'Live' && data?.state !== 'Offline') {
      return 'Save & Exit';
    } else if (!exitButtonEnable && data?.state !== 'Live' && data?.state !== 'Offline') {
      return 'Exit';
    } else if (data?.state == 'Live' || data?.state == 'Offline') {
      return 'Cancel';
    }
  };
  const button2Name = () => {
    if (isPublishButton && data?.state !== 'Live' && data?.state !== 'Offline') {
      return 'Publish';
    } else if (!isPublishButton && data?.state !== 'Live' && data?.state !== 'Offline') {
      return 'Next';
    } else if (data?.state == 'Live' || data?.state == 'Offline') {
      return 'Save & Exit';
    }
  };
  const handleButton = async () => {
    if (button1Name() != 'Cancel' && button1Name() != 'Exit') {
      setIsLoadingApi(true);
      if (data?.state !== 'Live' && data?.state !== 'Offline' && subheader === 'Course Addition') {
        await refreshList();
      }
      if (exitButtonEnable && data?.learningPathId) {
        data?.state == 'Draft' && (await updateEditCourseDetails());
      } else {
        data?.state !== 'Live' && data?.state !== 'Offline' && saveAndExitClick();
      }
      setTimeout(() => {
        setMainImageBackup(null);
        closeCourseWizard();
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setImageData({
          imageName: null,
          imageDescription: null,
          pillsData: null,
        });
        setNewImageData(null);
        setCroppedImageData(null);
        setCroppedImage_16_9(null);
        setCroppedImage_1_1(null);
        setSelectedImage_16_9_Backup(null);
        setSelectedImage_1_1_Backup(null);
      }, 1000);
    } else if (button1Name() == 'Cancel' || button1Name() == 'Exit') {
      setMainImageBackup(null);
      closeCourseWizard();
      setNewCourseWizardState({
        courseWizardOpen: false,
        courseWizardStateOpen: '',
        section: '',
      });
      setImageData({
        imageName: null,
        imageDescription: null,
        pillsData: null,
      });
      setNewImageData(null);
      setCroppedImageData(null);
      setCroppedImage_16_9(null);
      setCroppedImage_1_1(null);
      setSelectedImage_16_9_Backup(null);
      setSelectedImage_1_1_Backup(null);
    }

    setTimeout(() => {
      setIsLoadingApi(false);
    }, 2000);
  };
  const handleNextButton = () => {
    setStepProgress(stepProgress + 1);
    // let tempArr = [];
    let headerIndex = list.findIndex((data) => {
      return data.title == subheader;
    });
    if (data?.state !== 'Live' && data?.state !== 'Offline') {
      if (subheader === 'Course Addition') {
        setBtn2SaveAndExitDisable(true);
        refreshList();
      }
      if (subheader === 'Categories, Skills, Tags') {
        setIsPublishButton(true);
        refreshList();
      }
      if (subheader === 'Review & Publish') {
        publishLpData(true);
        setIsLoadingApi(true);
        setTimeout(() => {
          setIsLoadingApi(false);
        }, 1000);
      }
      if (subheader !== 'Review & Publish') {
        setSubheader(list[headerIndex + 1].title);
      } else {
        return;
      }
    } else if (data?.state == 'Live' || data?.state == 'Offline') {
      setBtn2SaveAndExitDisable(true);
      setSaveAndExitDisable(true);
      updateEditCourseDetails();
    }
  };
  const subHeaderCategoriesClick = (val) => {
    if (allCheckFunc()) {
      setNextButtonDisable(false);
    } else {
      setNextButtonDisable(true);
    }
  };

  const subHeaderDescriptionClick = (val) => {
    if (
      data &&
      (data?.primaryTitle?.length >= 3 || data?.data?.multilingualDetails[0].title?.length >= 3) &&
      (data?.primaryDescription?.length >= 3 ||
        data?.data?.multilingualDetails[0].description?.length >= 3)
    ) {
      setNextButtonDisable(false);
      setBlockNavigation(true);
    } else {
      setNextButtonDisable(true);
    }
  };

  const updateUserGroups = () => {
    let addUserList = [];
    let deleteUserList = [];
    addUserGroupList?.forEach((ele) => {
      addUserList.push({
        audGrpId: ele?.id || ele?.audGrpId,
        courseType: ele?.courseType,
      });
    });
    deleteUserGroupList?.forEach((ele) => {
      deleteUserList.push({
        audGrpId: ele?.id || ele?.audGrpId,
        courseType: ele?.courseType,
      });
    });

    setData((prevData) => ({
      ...prevData,
      audienceGroups: {
        deleteAudGrpIds: deleteUserList,
        addAudGrpIds: addUserList,
      },
    }));
  };

  useEffect(() => {
    !dataLP && !newUGMListState && handleUserTargetingGroupsList();
    dataLP?.audienceGroups && handleUserTargetingGroupsListDelete();
  }, [audienceGroup, dataLP, newUGMListState]);

  useEffect(() => {
    updateUserGroups();
  }, [addUserGroupList, deleteUserGroupList]);

  const handleUserTargetingGroupsListDelete = () => {
    let tempArr = [];
    let resultsDelete = [];
    if (audienceGroup?.length !== addUserGroupList?.length) {
      const results = audienceGroup?.filter(
        ({id: id1}) => !addUserGroupList.some(({id: id2}) => id2 === id1),
      );
      const ids = results.map((o) => o.id);
      resultsDelete = results.filter(({id}, index) => !ids.includes(id, index + 1));
      //resultsDelete = results?.filter(({ id: id1 }) => !deleteUserGroupList.some(({ id: id2 }) => id2 === id1));
      resultsDelete?.forEach((data) => {
        tempArr.push({
          id: data.id,
          title: data.title,
          courseType: 'General visibility',
          audienceGrpDescription: data?.audienceGrpDescription,
        });
      });
    }
    setDeleteUserGroupList(tempArr);
  };

  const handleArchivedInput = (value) => {
    setInput(value);
  };

  const handleUserTargetingGroupsList = () => {
    let obj = {};
    let tempArr = [];
    audienceGroup?.forEach((data) => {
      obj = {
        title: data.title,
        audGrpId: data.id,
        courseType: 'General visibility',
        audienceGrpDescription: data?.audienceGrpDescription,
      };
      tempArr.push(obj);
    });
    setAddUserGroupList(tempArr);
  };

  const handleDeleteUserTargetingGroups = (obj, list, element) => {
    let tempArr = [];
    tempArr = list;
    const index = list?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr.splice(index, 1, obj);
    }
    setAddUserGroupList(tempArr);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleAddUserTargetingGroups = (obj, list) => {
    let tempArr = [];
    let tempArr2 = deleteUserGroupList;
    tempArr = [...addUserGroupList, obj];
    setAddUserGroupList(tempArr);
    const index = deleteUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleDeleteUserGroups = (obj, list) => {
    let tempArr = [];
    let tempArr2 = addUserGroupList;
    const results = deleteUserGroupList?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    const val = addUserGroupListCopy?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    if (results == undefined) {
      if (val !== undefined) {
        tempArr = [...deleteUserGroupList, val];
      } else {
        tempArr = [...deleteUserGroupList, obj];
      }
    }
    const index = addUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr);
    setAddUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };

  const disableClickBanner = () => {
    data.state === 'Live' &&
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Editing User Group Targeting is disabled for Live Learning Paths.',
        description: `Editing User Groups is possible only when the Learning Path is turned Offline. You can turn the Learning Path Offline in the publish settings in Learning Path Details drawer.`,
      });
  };

  const setDeletedCourselist = (data) => {
    setAddedCourseList(data);
  };
  const fetchCoursesListRefresh = async (data) => {
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/courses/ids',
      body: data,
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      let tempArr = [];
      let categoryFullList = [];
      let skillsFullList = [];
      let keywordFullList = [];
      dataCourses?.data?.forEach((ele) => {
        tempArr.push({
          courseId: ele?.id,
          title: ele?.title,
          courseState: ele?.state,
          thumbnail: ele?.thumbnail,
          bannerImage: ele?.bannerImage,
          category: ele?.category,
          skills: ele?.skills,
          keywords: ele?.keywords,
        });
        ele?.category?.forEach((element) => {
          var findItem = categoryFullList.find((dld) => dld.id === element.id);
          if (!findItem) categoryFullList.push(element);
        });
        ele?.keywords?.forEach((element) => {
          var findItem = keywordFullList.find((dld) => dld.id === element.id);
          if (!findItem) keywordFullList.push(element);
        });

        ele?.skills?.forEach((element) => {
          var findItem = skillsFullList.find((dld) => dld.id === element.id);
          var findItemSelected = selectedSkills.find((dld) => dld.id === element.id);

          if (!findItem && !findItemSelected)
            skillsFullList.push({
              id: element.id,
              title: element.title,
              selected: false,
            });
        });

        // dataLP?.skills?.forEach((element) => {
        //   var findItem = skillsFullList.find((dld) => dld.id === element.id);
        //   var findIndex = skillsFullList.findIndex((dld) => dld.id === element.id);

        //   if (!findItem) {
        //     skillsFullList.push({
        //       id: element.id,
        //       title: element.title,
        //       selected: true,
        //     });
        //   } else if (findItem) {
        //     skillsFullList.splice(findIndex, 1, {
        //       id: element.id,
        //       title: element.title,
        //       selected: true,
        //     });
        //   }
        // });

        // let LplistsIndex = dataLP.skills.includes((dld) => {
        //   dld.id === element.id;
        // });

        //
      });
      setCategoriesListFull(categoryFullList);
      setKeywordsListFull(keywordFullList);
      // let tempArrSkills = [];
      //  let tempArrNew = [];
      // tempArrSkills = [...skillsFullList, ...skills];
      skills?.forEach((element) => {
        var findItem = skillsFullList.find((dld) => dld.id === element.id);
        var findItemSelected = selectedSkills.find((dld) => dld.id === element.id);

        if (!findItem && !findItemSelected)
          skillsFullList.push({
            id: element.id,
            title: element.title,
            selected: false,
          });
      });
      //tempArrNew = [...new Set(tempArrSkills)];
      setSkillsListFull(skillsFullList);
      setAddedCourseList(tempArr);
      setIsRefresh(false);
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
      setIsRefresh(false);
    }
  };
  const refreshList = async () => {
    setIsRefresh(true);
    let tempArr = [];
    let tempObj = {};
    addedCourseList.forEach((data) => {
      tempArr.push(data.courseId);
    });
    tempObj = {
      courseIds: tempArr,
    };
    await fetchCoursesListRefresh(tempObj);
  };

  const handleListRemove = (element) => {
    let temp = [];
    let sleArr = [];
    temp = [...skillsListFull];
    sleArr = [...selectedSkills];
    var findItem = temp.find((dld) => dld.id === element.id);
    var findIndex = sleArr.findIndex((dld) => dld.id === element.id);
    if (!findItem) {
      temp.push({
        id: element.id,
        title: element.title,
        selected: false,
      });
    }
    if (findIndex !== -1) {
      sleArr.splice(findIndex, 1);
    }
    setSkillsListFull(temp);
    setSelectedSkills(sleArr);
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };
  const handleListAdd = (element) => {
    let temp = [];
    let sleArr = [];

    sleArr = [...skillsListFull];
    temp = [...selectedSkills];
    var findItem = temp.find((dld) => dld.id === element.id);
    var findIndex = sleArr.findIndex((dld) => dld.id === element.id);
    if (!findItem) {
      temp.push({
        id: element.id,
        title: element.title,
        selected: true,
      });
      sleArr.splice(findIndex, 1);
    }
    setSelectedSkills(temp);
    setSkillsListFull(sleArr);
    if (
      getEnableSaveAndExit({
        englishTitle: data?.primaryTitle,
        englishDescription: data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };
  useEffect(() => {
    data?.primaryTitle?.length >= 3 &&
    data?.multilingualDetails &&
    data?.multilingualDetails[0]?.title?.length >= 3
      ? setIsTitle(true)
      : setIsTitle(false);
    data?.primaryDescription?.length >= 3 &&
    data?.multilingualDetails &&
    data?.multilingualDetails[0]?.description?.length >= 3
      ? setIsDescription(true)
      : setIsDescription(false);
    data?.thumbnailId ? setIsThumbnail(true) : setIsThumbnail(false);
    data?.state === 'Live' && setDisableStateUserManagement(true);
    data?.state === 'Offline' && setDisableStateUserManagement(false);
  }, [data]);

  return (
    <React.Fragment>
      {/* Main Container start */}
      <div
        className={[styles.course_wizard_container, ''].join(' ')}
        ref={containerRef}
        id={'course_wrapper'}
      >
        {/* Header Component */}
        <Header
          isLoading={isLoadingApi}
          headerName={
            dataLP && dataLP?.primaryTitle
              ? dataLP?.primaryTitle
              : newCourseData?.data?.primaryTitle
              ? newCourseData?.data?.primaryTitle
              : 'Create New Learning Path Wizard'
          }
          headerXSName=" "
          btnName1={button1Name()}
          btnName2={button2Name()}
          stepProgressBar={stepProgress}
          isHeaderXS={false}
          isBtn1={true}
          isBtn2={true}
          headerButtons={true}
          isProgressBar={editState ? false : true}
          isBtn2Disable={btn2SaveAndExitDisable}
          isBtn1Disable={saveAndExitDisable}
          handleButton={() => {
            setThumbnailImage169(null);
            handleButton();
          }}
          handleNextButton={() => {
            setThumbnailImage169(null);
            handleNextButton();
          }}
          subHeaderDescriptionClick={(val) => {
            subHeaderDescriptionClick(val);
          }}
          subHeaderCategoriesClick={(val) => {
            subHeaderCategoriesClick(val);
          }}
          showSubHeader={true}
          list={list}
          selectedSubHeaderNew={(data) => {
            setSubheader(data);
            if (data == 'Review & Publish') {
              refreshList();
            }
          }}
          subheader={subheader}
        />
        {/* btnName1={editState ? "Cancel" : exitButtonEnable ? "Exit" : "Save & Exit"}
          btnName2={nextState ? "Publish" : editState ? "Save & Exit" : "Next"} */}
        {/* Header Component */}

        {/* Learning Path Description Page Starts */}
        {subheader == 'Learning Path Description' ? (
          <div className={[styles.lp_wizard_wrapper, 'bootstrap-wrapper', ''].join(' ')}>
            <div
              className={[
                styles.inner_wrapper,
                styles.padding_class,
                styles.learning_path_description,
                '',
              ].join(' ')}
            >
              {/* Info container block */}
              <LearningPathDescription
                handleDataFill={(name, value) => handleDataFill(name, value)}
                handleDataFillMultiLingual={handleDataFillMultiLingual}
                data={data}
                uploadImageFromDesktop={uploadImageFromDesktop}
                setLearningPath={setLearningPath}
                setImageData={setImageData}
                disableImageLib={disableImageLib}
                thumbnailImage169={thumbnailImage169}
                setTypeCheckbox={setTypeCheckbox}
                filterListById={filterListById}
                pillsAddAudienceGroup={pillsAddAudienceGroup}
                setPillsAddAudienceGroup={setPillsAddAudienceGroup}
                handleDataFillAudienceGroups={handleDataFillAudienceGroups}
                mainImageBackup={mainImageBackup}
                typesCheckbox={typesCheckbox}
                setThumbnailImage169={setThumbnailImage169}
                setNewLPData={setNewLPData}
                selectedSkills={selectedSkills}
                pillsDataKeyword={pillsDataKeyword}
                categoriesListFull={categoriesListFull}
                setNewLPDataCST={setNewLPDataCST}
                newLPWizardState={newLPWizardState}
                setNewLPWizardState={setNewLPWizardState}
                newLPData={newLPData}
                certificateList={certificateList}
                setData={setData}
                setNewThumbnailImageData={setNewThumbnailImageData}
                uploadNewImagePath={uploadNewImagePath}
                isLoadingApi={isLoadingApi}
                addedCourseList={addedCourseList}
                setNewCourseAddedList={setNewCourseAddedList}
                setNewAddDeleteUGMList={setNewAddDeleteUGMList}
                addUserGroupList={addUserGroupList}
                deleteUserGroupList={deleteUserGroupList}
                addedCourseListState={addedCourseListState}
                setAddedCourseList={(data) => {
                  setAddedCourseList(data);
                }}
                setNewImageData={setNewImageData}
                setMainImageBackup={setMainImageBackup}
                setCroppedImageData={setCroppedImageData}
                setCroppedImage_16_9={setCroppedImage_16_9}
                setCroppedImage_1_1={setCroppedImage_1_1}
                setSelectedImage_16_9_Backup={setSelectedImage_16_9_Backup}
                setSelectedImage_1_1_Backup={setSelectedImage_1_1_Backup}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {/*  Learning Path Description Page Ends */}
        {/* Confirm Archive POPUP Starts */}
        {openArchivedModal ? (
          <div className={[styles.modal_wrapper, ''].join(' ')}>
            <Background backgroundFlag="small">
              <ModalWrapper modalFlag="small">
                {/* <div className={styles.delete_modal}> */}
                <div
                  className={styles.close_wrapper}
                  onClick={() => {
                    setOpenArchivedModal(false);
                    handleArchivedInput('');
                  }}
                >
                  <div className={styles.close_icon} />
                </div>
                <div className={styles.header_main_wrapper}>
                  <div className={styles.header_wrapper}>
                    <div className={styles.heading}>Confirm Archive</div>
                  </div>
                </div>

                <>
                  <div className={styles.warning_section}>
                    <div className={styles.warning_icon} />
                    <div className={styles.message_section}>
                      <div className={[styles.heading, styles.mb_5].join(' ')}>
                        You are about to Archive {dataLP?.primaryTitle}
                      </div>
                      <div className={styles.sub_heading}>This action cannot be undone.</div>
                    </div>
                  </div>

                  <div className={styles.confirm_wrapper}>
                    <div className={styles.txt}>
                      To permanently archive this Learning Path, type ARCHIVE.
                    </div>
                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder="Type ARCHIVE here"
                        // value={input}
                        onChange={(e) => handleArchivedInput(e.target.value)}
                        className={[
                          styles.input,
                          input.length > 0 && input.length !== 0 && input !== 'ARCHIVE'
                            ? styles.error_input
                            : '',
                        ].join(' ')}
                      />
                      {input.length > 0 && input.length !== 0 && input !== 'ARCHIVE' ? (
                        <>
                          <div className={styles.error_icon} />
                          <div className={styles.error_msg}>Error in character input</div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>

                <div className={styles.btn_wrapper}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      setOpenArchivedModal(false);
                      handleArchivedInput('');
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={[
                      styles.btn,
                      input.length > 0 && input === 'ARCHIVE' ? styles.active : styles.disabled,
                    ].join(' ')}
                    onClick={() => {
                      setOpenArchivedModal(false);
                      changeLearningPathStatus(data.learningPathId, 'archive');
                      handleArchivedInput('');
                    }}
                  >
                    Archive
                  </button>
                </div>
                {/* </div> */}
              </ModalWrapper>
            </Background>
          </div>
        ) : null}
        {/* Confirm Archive POPUP Ends */}

        {/* Course Addition LP Starts */}
        {subheader == 'Course Addition' ? (
          <CreateCourseAddition
            subheader={subheader}
            setErrorStateTemplate={setErrorStateTemplate}
            setAddedCourseList={(data) => {
              setAddedCourseList(data);
              if (
                getEnableSaveAndExit({
                  englishTitle: data?.primaryTitle,
                  englishDescription: data?.primaryDescription,
                })
              ) {
                setBtn2SaveAndExitDisable(false);
              }
            }}
            addedCourseListNew={addedCourseList}
            setNotificationData={setNotificationData}
            categories={categories}
            data={data}
            isLoadingApi={isLoadingApi}
          />
        ) : (
          ''
        )}
        {/* Course Addition LP Starts Ends */}

        {/* Course Addition LP Starts */}
        {subheader == 'Course Sequencing' ? (
          <CourseSequencing
            data={data}
            setErrorStateTemplate={setErrorStateTemplate}
            addedCourseList={addedCourseList}
            setDeletedCourselist={(data) => {
              setDeletedCourselist(data);
              if (
                getEnableSaveAndExit({
                  englishTitle: data?.primaryTitle,
                  englishDescription: data?.primaryDescription,
                })
              ) {
                setBtn2SaveAndExitDisable(false);
              }
            }}
            setSubheader={(data) => setSubheader(data)}
            subheader={subheader}
            setAddedCourseList={(ele) => {
              setAddedCourseList(ele);
              if (
                getEnableSaveAndExit({
                  englishTitle: data?.primaryTitle,
                  englishDescription: data?.primaryDescription,
                })
              ) {
                setBtn2SaveAndExitDisable(false);
              }
            }}
            refreshList={refreshList}
            isRefresh={isRefresh}
            setNotificationData={setNotificationData}
          />
        ) : (
          ''
        )}
        {subheader == 'User Group Targeting' ? (
          <div className={[styles.lp_wizard_wrapper, 'bootstrap-wrapper', ''].join(' ')}>
            <div className={[styles.inner_wrapper, styles.padding_class, ''].join(' ')}>
              <div className={styles.row_wrapper}>
                <UserTargetingLP
                  deleteUserGroupList={deleteUserGroupList}
                  addUserGroupList={addUserGroupList}
                  disableStateUserManagement={disableStateUserManagement}
                  handleDeleteUserTargetingGroups={(ele, id) => {
                    handleDeleteUserTargetingGroups(ele, id);
                    if (
                      getEnableSaveAndExit({
                        englishTitle: data?.primaryTitle,
                        englishDescription: data?.primaryDescription,
                      })
                    ) {
                      setBtn2SaveAndExitDisable(false);
                    }
                  }}
                  handleDeleteUserGroups={(ele) => {
                    handleDeleteUserGroups(ele);
                    if (
                      getEnableSaveAndExit({
                        englishTitle: data?.primaryTitle,
                        englishDescription: data?.primaryDescription,
                      })
                    ) {
                      setBtn2SaveAndExitDisable(false);
                    }
                  }}
                  disableClickBanner={disableClickBanner}
                  handleAddUserTargetingGroups={(ele) => {
                    handleAddUserTargetingGroups(ele);
                    if (
                      getEnableSaveAndExit({
                        englishTitle: data?.primaryTitle,
                        englishDescription: data?.primaryDescription,
                      })
                    ) {
                      setBtn2SaveAndExitDisable(false);
                    }
                  }}
                  isLoadingApi={isLoadingApi}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {subheader == 'Categories, Skills, Tags' ? (
          <div className={[styles.lp_wizard_wrapper, 'bootstrap-wrapper', ''].join(' ')}>
            <div className={[''].join(' ')}>
              <div className={styles.row_wrapper}>
                {' '}
                <CategorySkillsTagsLP
                  isRefresh={isRefresh}
                  addedCourseList={addedCourseList}
                  setSubheader={(data) => setSubheader(data)}
                  fetchSkills={fetchSkills}
                  skillsListFull={skillsListFull}
                  selectedSkills={selectedSkills}
                  keywordsListFull={keywordsListFull}
                  categoriesListFull={categoriesListFull}
                  setSkillsListFull={(ele) => {
                    setSkillsListFull(ele);
                  }}
                  refreshList={refreshList}
                  keyword={keyword}
                  filterListById={filterListById}
                  pillsDataKeyword={pillsDataKeyword}
                  handleDataFill={handleDataFill}
                  setPillsDataKeyword={(ele) => {
                    setPillsDataKeyword(ele);
                    setKeywordsListFromLpDetails(ele);
                    if (
                      getEnableSaveAndExit({
                        englishTitle: data?.primaryTitle,
                        englishDescription: data?.primaryDescription,
                      })
                    ) {
                      setBtn2SaveAndExitDisable(false);
                    }
                  }}
                  handleListRemove={handleListRemove}
                  handleListAdd={handleListAdd}
                  isLoadingApi={isLoadingApi}
                />{' '}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {subheader == 'Review & Publish' ? (
          <div className={[styles.lp_wizard_wrapper, 'bootstrap-wrapper', ''].join(' ')}>
            <div className={[''].join(' ')}>
              <div className={styles.row_wrapper}>
                <ReviewAndPublish
                  data={data}
                  isTitle={isTitle}
                  isDescription={isDescription}
                  isThumbnail={isThumbnail}
                  addedCourseList={addedCourseList}
                  handleGoToDescriptionPage={() => setSubheader('Learning Path Description')}
                  handleGoToSequencingPage={() => setSubheader('Course Sequencing')}
                  openArchivedModal={(ele) => setOpenArchivedModal(ele)}
                  isLoadingApi={isLoadingApi}
                />{' '}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {/* Course Addition LP Starts Ends */}
      </div>
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

CreateNewLearningPath.props = {};
const mapStateToProps = (state, ownProps) => ({
  allState: state,
  allProps: ownProps,
  notificationData: state && state.uiState && state.uiState.notificationData,
  newCourseData: state && state.courseState && state.courseState.newCourseData,
  newImageData: state && state.courseState && state.courseState.newImageData,
  newThumbnailImage: state && state.courseState && state.courseState.newThumbnailImage,
  mainImageBackup: state && state.croppedImage && state.croppedImage.mainImageBackup,
  newAdaptFile: state && state.courseState && state.courseState.newAdaptFile,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  newThumbnailId: state && state.courseState && state.courseState.newThumbnailId,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  thumbnailImage169: state && state.croppedImage && state.croppedImage.thumbnailImage169,
  newLPData: state && state.LPState && state.LPState.newLPData,
  newLPDataCST: state && state.LPState && state.LPState.newLPDataCST,
  addedCourseListState: state && state.LPState && state.LPState.addedCourseListState,
  newLPWizardState: state && state.LPState && state.LPState.newLPWizardState,
  newUGMListState: state && state.LPState && state.LPState.newUGMListState,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setNewCourseData: (options) => dispatch(setNewCourseData(options)),
  setNewImageData: (options) => dispatch(setNewImageData(options)),
  setNewCourseWizardState: (options) => dispatch(setNewCourseWizardState(options)),
  setNewAdaptFile: (options) => dispatch(setNewAdaptFile(options)),
  setLearningPath: (options) => dispatch(setLearningPath(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setSelectedImage_16_9_Backup: (options) => dispatch(setSelectedImage_16_9_Backup(options)),
  setSelectedImage_1_1_Backup: (options) => dispatch(setSelectedImage_1_1_Backup(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setNewThumbnailImageData: (options) => dispatch(setNewThumbnailImageData(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setThumbnailImage169: (options) => dispatch(setThumbnailImage169(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setNewLPWizardState: (options) => dispatch(setNewLPWizardState(options)),
  setNewLPData: (options) => dispatch(setNewLPData(options)),
  setNewLPDataCST: (options) => dispatch(setNewLPDataCST(options)),
  setNewCourseAddedList: (options) => dispatch(setNewCourseAddedList(options)),
  setNewAddDeleteUGMList: (options) => dispatch(setNewAddDeleteUGMList(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CreateNewLearningPath));
